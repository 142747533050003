import React from "react";
import { Router } from "@reach/router";
import { Container } from "react-bootstrap";

import Layout from "components/Layout";
import SEO from "components/Seo";

import Main from "components/dashboard/Main";
import Login from "components/dashboard/Login";
import Register from "components/dashboard/Register";
import UpdateUser from "components/dashboard/UpdateUser";
import UpdatePassword from "components/dashboard/UpdatePassword";
import ResetPassword from "components/dashboard/ResetPassword";
import ConfirmReset from "components/dashboard/ConfirmReset";
import ConfirmRegistration from "components/dashboard/ConfirmRegistration";
import PrivateRoute from "components/PrivateRoute";

const DashboardPage = () => (
  <Layout>
    <SEO title="Dashboard" />
    <section>
      <Container>
        <Router basepath="/dashboard">
          <PrivateRoute path="/" Component={Main} />
          <PrivateRoute path="/update-user" Component={UpdateUser} />
          <PrivateRoute path="/update-password" Component={UpdatePassword} />
          <ConfirmRegistration path="/confirm-registration/:id/:token" />
          <ConfirmReset path="/confirm-reset/:id/:token" />
          <Register path="/register" />
          <ResetPassword path="/reset-password" />
          <Login path="/login" />
        </Router>
      </Container>
    </section>
  </Layout>
);
export default DashboardPage;
