// import ...
import React, { useContext, useEffect } from "react";
import { navigate } from "gatsby";
import { isLoggedIn, isBrowser } from "services/auth";
import { cartContext } from "providers/CartProvider";

const PrivateRoute = ({ Component, location, ...rest }) => {
  const siteContext = useContext(cartContext);

  useEffect(() => {
    function toastIt() {
      siteContext.makeToast(
        "You must be logged in to view that page.",
        "error",
        "wrongpage"
      );
    }

    if (!isLoggedIn(siteContext) && location.pathname !== `/dashboard/login`) {
      toastIt();
      siteContext.removeToken();
      navigate("/dashboard/login");
    }
  }, [siteContext, location.pathname]);

  if (!isBrowser()) {
    return null;
  }

  if (!isLoggedIn(siteContext) && location.pathname !== `/dashboard/login`) {
    return null;
  }
  return <Component location={location} {...rest} />;
};
export default PrivateRoute;
