import React, { useState, useContext } from "react";
import axios from "axios";
import { Link } from "gatsby";
import { navigate } from "@reach/router";

import UpdatePasswordForm from "components/forms/UpdatePasswordForm";
import { cartContext } from "providers/CartProvider";

const UpdatePassword = () => {
  const [inputs, setInputs] = useState({
    password: "",
    pconfirm: "",
  });

  const siteContext = useContext(cartContext);

  const handleSubmit = async values => {
    try {
      const response = await axios.put(
        `${process.env.GATSBY_API_URL}/users/update-password`,
        values,
        {
          headers: {
            Authorization: `Bearer ${siteContext.getCookieToken()}`,
          },
          validateStatus(status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (response && response.status === 200) {
        siteContext.makeToast("Password updated.", "success");
        navigate("/dashboard");
      } else {
        siteContext.makeToast("Unable to change password.", "error");
      }
    } catch (err) {
      siteContext.makeToast("Server error, password not changed.", "error");
    }
  };

  return (
    <div>
      <h2>Update Password</h2>

      <Link to="/dashboard" className="btn btn-sm btn-info">
        Cancel
      </Link>

      <hr />

      <UpdatePasswordForm submitIt={handleSubmit} />
    </div>
  );
};

export default UpdatePassword;
