import React from "react";
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";

import { registrationValidationSchema } from "helpers/validation";

const UserRegisterForm = ({ submitIt }) => (
  <Formik
    initialValues={{
      namefirst: "",
      namelast: "",
      email: "",
      password: "",
      passwordconfirm: "",
    }}
    validationSchema={registrationValidationSchema}
    onSubmit={(values, actions) => {
      // alert(JSON.stringify(values, null, 2));
      submitIt(values);
      actions.setSubmitting(false);
    }}
  >
    {props => (
      <Form className="form">
        <div className="form-row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="namefirst">First Name:</label>
              <Field
                name="namefirst"
                placeholder="First Name"
                className="form-control"
                required
              />
              <ErrorMessage
                name="namefirst"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label htmlFor="namelast">Last Name:</label>
              <Field
                name="namelast"
                placeholder="Last Name"
                className="form-control"
                required
              />
              <ErrorMessage
                name="namelast"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="email">Email Address:</label>
              <Field
                name="email"
                placeholder="Your Email"
                type="email"
                className="form-control"
                required
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="password">Create Password:</label>
              <Field
                name="password"
                type="password"
                className="form-control"
                required
              />
              <ErrorMessage
                name="password"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="passwordconfirm">Re-type Password:</label>
              <Field
                name="passwordconfirm"
                type="password"
                className="form-control"
                required
              />
              <ErrorMessage
                name="passwordconfirm"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-success">
          Submit
        </button>
      </Form>
    )}
  </Formik>
);

export default UserRegisterForm;
