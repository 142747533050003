import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "gatsby";
import { navigate } from "@reach/router";

import UpdateUserDetailsForm from "components/forms/UpdateUserDetailsForm";
import { cartContext } from "providers/CartProvider";

const UpdateUser = ({ location }) => {
  const initData = location.state.userData;
  const siteContext = useContext(cartContext);

  const handleSubmit = async values => {
    try {
      const response = await axios.put(
        `${process.env.GATSBY_API_URL}/users/update/${initData.id}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${siteContext.getCookieToken()}`,
          },
          validateStatus(status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (response.status === 200) {
        siteContext.makeToast("Details updated.", "success");
        return navigate("/dashboard");
      }

      return siteContext.makeToast(response.data, "error");
    } catch (e) {
      siteContext.makeToast("There was a problem updating your info.", "error");
    }
  };

  return (
    <div>
      <h2>Update Your Details</h2>

      <Link to="/dashboard" className="btn btn-sm btn-info">
        Cancel
      </Link>

      <hr />

      <UpdateUserDetailsForm submitIt={handleSubmit} initVals={initData} />
    </div>
  );
};

export default UpdateUser;
