import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "gatsby";
import { navigate } from "@reach/router";
import { Helmet } from "react-helmet";

import UserRegisterForm from "components/forms/UserRegisterForm";
import { isLoggedIn } from "services/auth";
import { cartContext } from "providers/CartProvider";

const Register = () => {
  const siteContext = useContext(cartContext);

  useEffect(() => {
    if (siteContext && isLoggedIn(siteContext)) {
      navigate(`/dashboard/`);
    }
  }, [siteContext]);

  const handleSubmit = async values => {
    try {
      const response = await axios.post(
        `${process.env.GATSBY_API_URL}/users/register`,
        values,
        {
          validateStatus(status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (response.status === 200) {
        // console.log(response.data.message);
        siteContext.makeToast(
          "Registration successful. Please check your mail for a confirmation link.",
          "success"
        );
        return navigate("/dashboard/login");
      }

      // console.log("response: ", response);
      return siteContext.makeToast(response.data, "error");
    } catch (e) {
      siteContext.makeToast(
        "There was a problem with your registration.",
        "error"
      );
    }
  };

  const handleConfirm = (vals, actions) => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(process.env.GATSBY_RECAPTCHA_PUBLIC, { action: "register" })
          .then(function (token) {
            // console.log("token: ", token);
            handleSubmit({ token, ...vals }, actions);
          });
      });
    }
  };

  return (
    <div>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_PUBLIC}`}
        />
      </Helmet>
      <h2>Register Account</h2>

      <ol className="font-weight-bold">
        <li>
          <p>Fill out the form below to create a new account.</p>
        </li>
        <li>
          <p>
            You will then receive an email with a link, which you will need to
            click to verify/confirm your account.
          </p>
        </li>
        <li>
          <p className="text-danger">
            You will NOT be able to login until you have confirmed your account
            this way.
          </p>
        </li>
      </ol>

      <p className="pt-3">
        Already have an account? <Link to="/dashboard/login">Login here</Link>.
      </p>

      <hr />

      <UserRegisterForm submitIt={handleConfirm} />
    </div>
  );
};

export default Register;
