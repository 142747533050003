import React, { useState, useContext, useEffect } from "react";
import { Link, navigate } from "@reach/router";
import { Helmet } from "react-helmet";

import UserLoginForm from "components/forms/UserLoginForm";
import { handleLogin, isLoggedIn } from "services/auth";
import { cartContext } from "providers/CartProvider";

const Login = () => {
  const siteContext = useContext(cartContext);

  useEffect(() => {
    if (siteContext && isLoggedIn(siteContext)) {
      navigate(`/dashboard/`);
    }
  }, [siteContext]);

  const handleSubmit = async values => {
    try {
      const response = await handleLogin(values, siteContext);
    } catch (e) {
      siteContext.makeToast("Error logging in.", "error");
    }
  };

  const handleConfirm = (vals, actions) => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(process.env.GATSBY_RECAPTCHA_PUBLIC, { action: "login" })
          .then(function (token) {
            // console.log("token: ", token);
            handleSubmit({ token, ...vals }, actions);
          });
      });
    }
  };

  return (
    <div>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_PUBLIC}`}
        />
      </Helmet>

      <h2>Login</h2>

      <p className="font-weight-bold">
        No account? <Link to="/dashboard/register">Click here</Link> to
        register.
      </p>

      {/* <UserLoginForm submitIt={handleConfirm} /> */}
      <p>
        Login disabled. (Too many of you couldn't figure out how to confirm your
        registrations, so we removed the dashboard.)
      </p>

      <p className="mt-3 font-weight-bold">
        Forgot your password?{" "}
        <Link to="/dashboard/reset-password">Click here</Link> to reset it.
        We'll send an email to you with instructions.
      </p>
    </div>
  );
};

export default Login;
