import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "gatsby";
import { navigate } from "@reach/router";

import { cartContext } from "providers/CartProvider";

const ConfirmRegistration = ({ id, token }) => {
  const [confirmed, setConfirmed] = useState(false);
  const siteContext = useContext(cartContext);

  useEffect(() => {
    async function confirmUser() {
      try {
        const response = await axios.post(
          `${process.env.GATSBY_API_URL}/users/update-user-confirm`,
          { id, token },
          {
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        if (response && response.status === 200) {
          siteContext.makeToast("Account registraton confirmed.", "success");
          setConfirmed(true);
          // navigate("/dashboard/login");
        } else {
          siteContext.makeToast("Unable to confirm user.", "error");
        }
      } catch (err) {
        siteContext.makeToast(
          "Server error, user could not be confirmed.",
          "error"
        );
      }
    }

    confirmUser();
  }, [id, token, siteContext]);

  return (
    <div>
      <h2>Confirm New User</h2>

      {confirmed ? (
        <div>
          <p>
            Thank you for confirming your registration. You can now login and
            make purchases.
          </p>
          <Link to="/dashboard/login" className="btn btn-sm btn-info">
            Login
          </Link>
        </div>
      ) : (
        <p>User not confirmed.</p>
      )}
    </div>
  );
};

export default ConfirmRegistration;
